import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { FormHandles } from '@unform/core'
import {
  useControleDeCarregamento,
  useControleDeRequisicao
} from 'src/compartilhados'
import {
  Cabecalho,
  CepInputUnform,
  CheckboxUnform,
  CpfInputUnform,
  DadosAlteracaoCPFCNPJ,
  DataInputUnform,
  focarPrimeiroCampoComErro,
  FormUnform,
  IconeEditar,
  IconeModalSucesso,
  ImageInput,
  InputUnform,
  ModalAlteracaoCPFCPNJ,
  ModalRef,
  Navegacao,
  SubTitulo,
  TelefoneInputUnform,
  useBarraSuperior
} from 'src/componentes'
import { dataFormatadaFormatoAmericanoParaBrasileiro } from 'src/componentes/funcoes/data-hora'
import {
  SELECT_ESTADO_CIVIL,
  SELECT_NACIONALIDADES,
  SELECT_PAISES,
  SELECT_RACA,
  SELECT_SEXO
} from 'src/dados-estaticos'
import { RotasAluno } from 'src/rotas/aluno'
import { Api } from 'src/servicos'
import {
  SexoPorExtenso,
  Escolaridade,
  PaisResidencia,
  NacionalidadePorExtenso
} from 'src/tipos'

import { formularioDeEdicaoDoAlunoMapper } from './formulario-de-edicao-do-aluno.mapper'
import { normalizacaoDeDadosParaFormulario } from './normalizacao-de-dados-para-formulario'
import { schema } from './schema'
import {
  Conteudo,
  ContainerDados,
  ContainerDadosPequeno,
  Secao,
  Container,
  ContainerMain,
  OpcaoDados,
  CampoLabel,
  Opcao,
  ContainerDadosMedio,
  ContainerAcoes,
  ContainerLateral,
  RequisicaoComErros,
  CarregandoCursos,
  TituloPrincipal,
  SubTituloComEstilos,
  SpacerComEstilos,
  SelectUnformComEstilos,
  BotaoComEstilos,
  ContainerDoUploadDeImagem,
  ModalComEstilos,
  ContainerCPF
} from './styles'
import { TipoDadosDoFormulario, Navegacoes } from './tipos'

export const PaginaEdicaoAluno: React.FC = () => {
  const barraSuperior = useBarraSuperior()
  const requisicao = useControleDeRequisicao<TipoDadosDoFormulario, string>()
  const edicao = useControleDeCarregamento()
  const formRef = useRef<FormHandles>(null)

  const [possuiNomeSocial, definirPossuiNomeSocial] = useState(false)
  const [paisResidencia, definirPaisResidencia] = useState<string>(null)
  const [, definirPossuiRG] = useState<boolean>(true)
  const [possuiCPF, definirPossuiCPF] = useState<boolean>(true)
  const [generoAluno, definirGeneroAluno] = useState<string>('')
  const [nacionalidade, definirNacionalidade] = useState<string>('')
  const [emailAlterado, definirEmailAlterado] = useState(false)
  const [dataColacaoFormatada, definirDataColacaoFormatada] = useState<string>(
    ''
  )
  const modalRef = useRef<ModalRef>(null)
  const modalAvisoEmailRef = useRef<ModalRef>(null)
  const modalAlteracaoCPF = useRef<ModalRef>()
  const history = useHistory()

  const acaoSucesso = async (form: TipoDadosDoFormulario): Promise<void> => {
    try {
      edicao.alterarParaIniciado()
      const aluno = formularioDeEdicaoDoAlunoMapper(form)
      await Api.EditarAluno(aluno)
      if (emailAlterado) modalAvisoEmailRef?.current?.abrir()
      if (!emailAlterado) history.push(RotasAluno.Dashboard)
    } catch (erro) {
      toast('Ops! Aconteceu um erro. Tente novamente.', { type: 'error' })
    } finally {
      edicao.alterarParaConcluido()
    }
  }

  const callback = (form: TipoDadosDoFormulario): TipoDadosDoFormulario => {
    const estado = Boolean(form.nomeSocial)
    const documento = Boolean(form.rg)
    const cpf = form.nacionalidade === SELECT_NACIONALIDADES[0].id

    definirPossuiNomeSocial(estado)
    definirPossuiRG(documento)
    definirPossuiCPF(cpf)
    return form
  }

  const obterDadosDoAlunoParaEdicao = async (): Promise<void> => {
    try {
      requisicao.carregador.alterarParaIniciado()
      const resposta = await Api.ObterAluno()
      barraSuperior.atualizarFoto(resposta.foto)
      const dadosNormalizados = normalizacaoDeDadosParaFormulario(
        resposta,
        callback
      )
      requisicao.resposta.adicionarResposta(dadosNormalizados)
      // eslint-disable-next-line no-console
      console.log(resposta.mesAnoColacaoGrau)
      if (resposta.mesAnoColacaoGrau) {
        definirDataColacaoFormatada(
          dataFormatadaFormatoAmericanoParaBrasileiro(
            resposta?.mesAnoColacaoGrau
          )
        )
      }
    } catch (erro: any) {
      // eslint-disable-next-line no-console
      console.log(erro)
      requisicao.erros.adicionarErro(erro as string)
    } finally {
      requisicao.carregador.alterarParaConcluido()
    }
  }

  const atualizarDadosFormulario = (dados: DadosAlteracaoCPFCNPJ) => {
    if (dados.cpf) formRef.current?.setFieldValue('cpf', dados.cpf)
  }

  useEffect(() => {
    obterDadosDoAlunoParaEdicao()
    // eslint-disable-next-line no-console
    console.log('existem erros', requisicao.erros.existemErros)
    // eslint-disable-next-line no-console
    console.log(requisicao.resposta.obterResposta())
  }, [])

  if (requisicao.carregador.estaInativo()) {
    return (
      <Container>
        <ContainerMain>
          <Cabecalho>
            <TituloPrincipal>Atualização de Cadastro</TituloPrincipal>
          </Cabecalho>
          <Conteudo></Conteudo>
        </ContainerMain>
      </Container>
    )
  }

  if (requisicao.carregador.foiIniciado()) {
    return (
      <Container>
        <ContainerMain>
          <Cabecalho>
            <TituloPrincipal>Atualização de Cadastro</TituloPrincipal>
          </Cabecalho>
          <Conteudo>
            <CarregandoCursos>Carregando dados...</CarregandoCursos>
          </Conteudo>
        </ContainerMain>
      </Container>
    )
  }

  if (requisicao.carregador.foiConcluido() && requisicao.erros.existemErros()) {
    return (
      <Container>
        <ContainerMain>
          <Cabecalho>
            <TituloPrincipal>Atualização de Cadastro</TituloPrincipal>
          </Cabecalho>
          <Conteudo>
            <RequisicaoComErros>
              Ops! Aconteceu um erro. Tente novamente.
            </RequisicaoComErros>
          </Conteudo>
        </ContainerMain>
      </Container>
    )
  }

  return (
    <Container>
      <ContainerMain>
        <Cabecalho>
          <TituloPrincipal>Atualização de Cadastro</TituloPrincipal>
        </Cabecalho>
        <Conteudo>
          <FormUnform
            ref={formRef}
            acaoSucesso={acaoSucesso}
            acaoFalha={focarPrimeiroCampoComErro}
            dadosIniciais={requisicao.resposta.obterResposta()}
            schema={schema}
          >
            <Secao id={Navegacoes.DadosPessoais}>
              <SubTituloComEstilos texto="Dados Pessoais" />
              <ContainerDoUploadDeImagem>
                <ImageInput id="foto" name="foto" label maxSize={2} />
              </ContainerDoUploadDeImagem>
              <SpacerComEstilos padding="10px" />
              <ContainerDados>
                <ContainerDadosMedio>
                  <SelectUnformComEstilos
                    id="slct_nacionalidade"
                    name="nacionalidade"
                    label="Nacionalidade"
                    placeholder="Selecione"
                    valorAlterado={valor => {
                      definirNacionalidade(valor.id)
                    }}
                    opcoes={SELECT_NACIONALIDADES}
                  />
                </ContainerDadosMedio>
                {nacionalidade === NacionalidadePorExtenso.brasileira && (
                  <ContainerCPF>
                    <CpfInputUnform
                      id="cpf"
                      name="cpf"
                      label="CPF"
                      disabled={possuiCPF}
                      obrigatorio
                    />
                    <button
                      type="button"
                      data-tip="Editar"
                      disabled={edicao.foiIniciado()}
                      onClick={() => modalAlteracaoCPF?.current?.abrir()}
                    >
                      {IconeEditar}
                    </button>
                  </ContainerCPF>
                )}
                <ContainerDadosPequeno>
                  <InputUnform
                    id="rg"
                    name="rg"
                    label="Documento de Identidade"
                    obrigatorio
                  />
                </ContainerDadosPequeno>
              </ContainerDados>
              <ContainerDados>
                <ContainerDadosPequeno>
                  <InputUnform id="orgao" name="orgao" label="Órgão Emissor" />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <InputUnform
                    id="codigoConselho"
                    name="codigoConselho"
                    label="Código do Conselho"
                  />
                </ContainerDadosPequeno>
              </ContainerDados>
              <InputUnform
                name="nome"
                label="Nome Completo"
                obrigatorio
                maxLength={200}
                disabled
              />
              <Opcao>
                <CampoLabel>Possui nome social?</CampoLabel>
                <OpcaoDados>
                  <CheckboxUnform
                    id="chk_possui_nome_social"
                    name="possuiNomeSocial"
                    onChange={() => definirPossuiNomeSocial(old => !old)}
                    checked={possuiNomeSocial}
                  />
                  <p>
                    <b>Sim</b>
                    <i>
                      ( Nome social é a adoção de nome diferente do oficialmente
                      registrado, de modo a identificar adequadamente sua
                      identidade de gênero - Decreto nº 8727/2016)
                    </i>
                  </p>
                </OpcaoDados>
              </Opcao>
              {possuiNomeSocial && (
                <InputUnform
                  name="nomeSocial"
                  label="Nome Social"
                  maxLength={200}
                  obrigatorio
                />
              )}
              <ContainerDados>
                <ContainerDadosPequeno>
                  <DataInputUnform
                    className="sm"
                    id="dataNascimento"
                    name="dataNascimento"
                    label="Data de Nascimento"
                    obrigatorio
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <SelectUnformComEstilos
                    id="slct_genero"
                    name="sexo"
                    label="Gênero"
                    placeholder="Selecione"
                    obrigatorio
                    opcoes={SELECT_SEXO}
                    valorAlterado={valor => definirGeneroAluno(valor.id)}
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <InputUnform
                    type="text"
                    name="generoOutros"
                    label="Qual?"
                    disabled={generoAluno !== SexoPorExtenso.Outros}
                    maxLength={200}
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <SelectUnformComEstilos
                    className="lg"
                    id="raca"
                    name="raca"
                    label="Raça"
                    placeholder="Selecione"
                    opcoes={SELECT_RACA}
                    obrigatorio
                  />
                </ContainerDadosPequeno>
              </ContainerDados>
              <ContainerDados>
                <ContainerDadosPequeno>
                  <SelectUnformComEstilos
                    className="lg"
                    id="estadoCivil"
                    name="estadoCivil"
                    label="Estado Civil"
                    placeholder="Selecione"
                    opcoes={SELECT_ESTADO_CIVIL}
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <InputUnform
                    type="text"
                    name="profissao"
                    label="Formação"
                    maxLength={200}
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <SelectUnformComEstilos
                    className="lg"
                    id="escolaridade"
                    name="escolaridade"
                    label="Escolaridade"
                    placeholder="Selecione"
                    opcoes={[
                      {
                        id: `${Escolaridade.Doutorado}`,
                        texto: 'Doutorado'
                      },
                      {
                        id: `${Escolaridade.PosDoutorado}`,
                        texto: 'Pós Doutorado'
                      },
                      {
                        id: `${Escolaridade.EnsinoMedio}`,
                        texto: 'Ensino Médio'
                      },
                      {
                        id: `${Escolaridade.EnsinoSuperiorCompleto}`,
                        texto: 'Ensino Superior Completo'
                      },
                      {
                        id: `${Escolaridade.EnsinoSuperiorEmAndamento}`,
                        texto: 'Ensino Superior em Andamento'
                      },
                      {
                        id: `${Escolaridade.Especializacao}`,
                        texto: 'Especialização'
                      },
                      {
                        id: `${Escolaridade.Mestrado}`,
                        texto: 'Mestrado'
                      },
                      {
                        id: `${Escolaridade.PosGraduado}`,
                        texto: 'Pós Graduação'
                      }
                    ]}
                    obrigatorio
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <InputUnform
                    className="sm"
                    id="dataColacao"
                    name="dataColacao"
                    label="Data da Colação de Grau"
                    placeholder="__/__/____"
                    mascara="99/99/9999"
                  />
                </ContainerDadosPequeno>
              </ContainerDados>
            </Secao>
            <Secao id={Navegacoes.Contato}>
              <SubTitulo texto="Contato" />
              <ContainerDados>
                <ContainerDadosMedio>
                  <InputUnform
                    className="md"
                    type="email"
                    name="email"
                    label="E-mail"
                    maxLength={200}
                    onChange={() => definirEmailAlterado(true)}
                  />
                </ContainerDadosMedio>
                <ContainerDadosPequeno>
                  <TelefoneInputUnform
                    className="sm"
                    name="celular"
                    label="Celular"
                    maxLength={11}
                    obrigatorio
                  />
                </ContainerDadosPequeno>
                <ContainerDadosPequeno>
                  <TelefoneInputUnform
                    className="sm"
                    name="telefone"
                    label="Telefone"
                    maxLength={11}
                  />
                </ContainerDadosPequeno>
              </ContainerDados>
            </Secao>
            <Secao id={Navegacoes.Residencia}>
              <SubTitulo texto="Residência" />
              <ContainerDados>
                <ContainerDadosMedio>
                  <SelectUnformComEstilos
                    name="paisResidencia"
                    id="paisResidencia"
                    label="País"
                    valorAlterado={selected =>
                      selected && definirPaisResidencia(selected.id)
                    }
                    opcoes={SELECT_PAISES}
                    obrigatorio
                  />
                </ContainerDadosMedio>
                {paisResidencia === `${PaisResidencia.Brasil}` ? (
                  <ContainerDadosPequeno>
                    <CepInputUnform
                      className="sm"
                      type="text"
                      name="cep"
                      label="CEP"
                      obrigatorio={
                        paisResidencia === `${PaisResidencia.Brasil}`
                      }
                    />
                  </ContainerDadosPequeno>
                ) : null}
              </ContainerDados>
              <InputUnform
                type="text"
                name="logradouro"
                label="Logradouro"
                maxLength={200}
                obrigatorio
              />
              <ContainerDados>
                <ContainerDadosMedio>
                  <InputUnform
                    className="sm"
                    type="text"
                    name="numero"
                    label="Número"
                    maxLength={200}
                    obrigatorio
                  />
                </ContainerDadosMedio>
                <ContainerDadosMedio>
                  <InputUnform
                    className="sm"
                    type="text"
                    name="complemento"
                    label="Complemento"
                    maxLength={200}
                  />
                </ContainerDadosMedio>
              </ContainerDados>
              <ContainerDados>
                <ContainerDadosMedio>
                  <InputUnform
                    type="text"
                    name="bairro"
                    label="Bairro"
                    maxLength={200}
                    obrigatorio
                    className="sm"
                  />
                </ContainerDadosMedio>
                <ContainerDadosMedio>
                  <InputUnform
                    type="text"
                    name="cidade"
                    label="Cidade"
                    maxLength={200}
                    obrigatorio
                  />
                </ContainerDadosMedio>
              </ContainerDados>
              <ContainerDadosMedio>
                <InputUnform
                  type="text"
                  name="uf"
                  label="UF"
                  maxLength={10}
                  obrigatorio
                  className="sm"
                />
              </ContainerDadosMedio>
            </Secao>
            <ContainerAcoes>
              <BotaoComEstilos
                texto="Cancelar"
                tema="Secundario"
                type="button"
                onClick={() => modalRef?.current?.abrir()}
              />
              <BotaoComEstilos texto="Salvar" disabled={edicao.foiIniciado()} />
            </ContainerAcoes>
          </FormUnform>
        </Conteudo>
      </ContainerMain>
      <ContainerLateral>
        <Navegacao
          itens={[
            {
              link: Navegacoes.DadosPessoais,
              nome: 'Dados Pessoais'
            },
            {
              link: Navegacoes.Contato,
              nome: 'Contato'
            },
            {
              link: Navegacoes.Residencia,
              nome: 'Residência'
            }
          ]}
        />
      </ContainerLateral>
      <ModalComEstilos
        ref={modalRef}
        backdrop
        id="modal-confirmacao-cancelamento"
        icone={<></>}
        titulo={'Deseja cancelar?'}
        acaoPrimaria={{
          titulo: 'Sim',
          tipo: 'button',
          acao: () => history.goBack()
        }}
        acaoSecundario={{
          titulo: 'Não',
          tipo: 'button',
          acao: () => modalRef?.current?.fechar()
        }}
      >
        <p>Selecione uma opção</p>
      </ModalComEstilos>
      <ModalComEstilos
        ref={modalAvisoEmailRef}
        backdrop
        id="modal-aviso-email"
        icone={<>{IconeModalSucesso}</>}
        titulo="E-mail alterado!"
        acaoPrimaria={{
          titulo: 'OK',
          tipo: 'button',
          acao: () => {
            modalAvisoEmailRef?.current?.fechar()
            history.push(RotasAluno.Dashboard)
          }
        }}
      >
        <p style={{ textAlign: 'center' }}>
          A solicitação do endereço de E-mail foi gerada. Acesse o novo endereço
          de e-mail cadastrado e confirme a alteração pelo link enviado.
        </p>
      </ModalComEstilos>
      <ModalAlteracaoCPFCPNJ
        ref={modalAlteracaoCPF}
        acaoPrimaria={atualizarDadosFormulario}
        backdrop
      />
    </Container>
  )
}
