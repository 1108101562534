import { FuncoesDataHora } from 'src/componentes'
import { Aluno } from 'src/tipos/aluno'
import { Endereco } from 'src/tipos/endereco'

import { TipoDadosDoFormulario } from './tipos'

export function formularioDeEdicaoDoAlunoMapper(
  formulario: TipoDadosDoFormulario
): Aluno {
  if (formulario === null) {
    return {} as Aluno
  }

  return ({
    cpf: formulario.cpf,
    documentoIdentidade: formulario.rg,
    codigoConselho: formulario.codigoConselho,
    nacionalidade: formulario.nacionalidade,
    orgaoEmissor: formulario.orgao,
    nome: formulario.nome,
    nomeSocial: formulario.nomeSocial,
    dataNascimento: formulario.dataNascimento,
    sexo: formulario.sexo,
    generoOutros: formulario.generoOutros,
    estadoCivil: formulario.estadoCivil,
    profissao: formulario.profissao,
    escolaridade: formulario.escolaridade,
    mesAnoColacaoGrau:
      formulario.dataColacao?.length > 1
        ? FuncoesDataHora.transformarPadraoAmericano(formulario.dataColacao)
        : '',
    telefone: formulario.telefone,
    celular: formulario.celular,
    email: formulario.email,
    corRaca: formulario.raca,
    foto: formulario.foto,
    endereco: {
      pais: formulario.paisResidencia,
      cep: formulario.cep,
      logradouro: formulario.logradouro,
      numero: formulario.numero,
      complemento: formulario.complemento,
      bairro: formulario.bairro,
      cidade: formulario.cidade,
      uf: formulario.uf
    } as Endereco
  } as unknown) as Aluno
}
